import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Impressum = () => (
  <Layout>
    <SEO title="Impressum" />
    <section className="is-medium has-background-grey-light">
      <div className="container dashboard">
        <div className="pad-20"></div>
        <h1 className="title">Impressum</h1>
        <p>
          Angaben gem&auml;&szlig; &sect; 5 TMG
          <br />
          <br />
        </p>
        <p>
          Rockit. UG <br /> Rathausstr. 13a
          <br /> 31134 Hildesheim
          <br />
          info@rockit.expert
          <br />
          Tel.: +49 (0)5121 98146-77
        </p>
        <p>
          <strong>Vertreten durch: </strong>
          <br />
          Andreas Lohrenz
          <br />
          <br />
        </p>
        <p>
          <strong>Registereintrag: </strong>
          <br /> Eintragung im Handelsregister.
          <br /> Registergericht: Hildesheim
          <br /> Registernummer: 206316
          <br />
          <br />
        </p>
        <p>
          <strong>Umsatzsteuer-ID: </strong> <br /> nicht vergeben
          <br />
          <br /> <strong>Wirtschafts-ID: </strong>
          <br />
          nicht vergeben
          <br />
          <br />
        </p>
        <p>
          <strong>Haftungsausschluss: </strong>
          <br />
          <br />
          <strong>Haftung f&uuml;r Inhalte</strong>
          <br />
          <br /> Die Inhalte unserer Seiten wurden mit gr&ouml;&szlig;ter
          Sorgfalt erstellt. F&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und
          Aktualit&auml;t der Inhalte k&ouml;nnen wir jedoch keine Gew&auml;hr
          &uuml;bernehmen. Als Diensteanbieter sind wir gem&auml;&szlig; &sect;
          7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den
          allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG
          sind wir als Diensteanbieter jedoch nicht verpflichtet,
          &uuml;bermittelte oder gespeicherte fremde Informationen zu
          &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine
          rechtswidrige T&auml;tigkeit hinweisen. Verpflichtungen zur Entfernung
          oder Sperrung der Nutzung von Informationen nach den allgemeinen
          Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche
          Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
          Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden
          Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          <br />
          <br />
          <strong>Haftung f&uuml;r Links</strong>
          <br />
          <br /> Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter,
          auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir
          f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen.
          F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige
          Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
          Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche
          Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte
          waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
          inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
          Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
          von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
          <br />
          <br />
          <strong>Urheberrecht</strong>
          <br />
          <br /> Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
          diesen Seiten unterliegen dem deutschen Urheberrecht. Die
          Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
          Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes
          bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
          Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den
          privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte
          auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
          Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
          als solche gekennzeichnet. Sollten Sie trotzdem auf eine
          Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
      </div>
    </section>
  </Layout>
)

export default Impressum
